import React from "react"
import Insta from "../../../assets/icons/instagram.svg"
import Face from "../../../assets/icons/facebook_1.svg"

import { Row, Col } from "react-bootstrap"

const Footer = () => {
  return (
    <Row className="justify-content-center footer">
      <Col xs={12} className="text-center">
        <a
          href="https://www.instagram.com/s.proteccion_radiologica"
          rel="noopener noreferrer"
          target="_BLANK"
        >
          <Insta />
        </a>
        <a
          href="https://www.facebook.com/solucionesenproteccionradiologica/"
          rel="noopener noreferrer"
          target="_BLANK"
        >
          <Face />
        </a>
      </Col>
      <Col xs={12} className="text-center mb-3">
        <p className="my-1 text-muted">
          © 2020 SPR. Todos los derechos reservados
        </p>
      </Col>
    </Row>
  )
}
export default Footer
